import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, ModalContent, ModalHeader, ModalBody, Textarea, Modal
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";
import {BlockUser} from "../../assets/icons/BlockUser";
import {WalletIcon} from "../../assets/icons/WalletIcon";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {AddIcon} from "../../assets/icons/AddIcon";
import {useAsyncList} from "@react-stately/data";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {PauseIcon} from "../../assets/icons/PauseIcon";
import {PlayIcon} from "../../assets/icons/PlayIcon";

const Club = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [clubDeleteModal, setClubDeleteModal] = useState(false);
    const [addClubModal, setAddClubModal] = useState(false);
    const [sendData, setSendData] = useState({
        "phone_number": "",
        "places_count": ""
    })
    const [clubFillModal, setClubFillModal] = useState(false);
    const [changeClubPartModal, setChangeClubPartModal] = useState(false);
    const [changeID, setChangeID] = useState(null);
    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);
    const [count, setCount] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasMore, setHasMore] = React.useState(false);
    const [searchDetails, setSearchDetails] = useState({
        name: "",
        branch: "",
        date_from: "",
        date_to: ""
    })

    const getUser = (user_id) => {
        axios.get(`https://backend.sorpa.com/users/${user_id}/get/admin/data/`).then(res => {
            setUser(res.data);
        }).catch(err => {
            console.log(err)
            setError(err.response.data.message);
        })
    }

    let list = useAsyncList({
        async load({signal, cursor}) {
            console.log(url);

            if (cursor) {
                setIsLoading(false);
            }

            const res = await axios.get(cursor || url);
            let json = await res.data

            setIsLoading(false);
            setUsers(json.results);
            setCount(json.count);
            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });

    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});

    let url = "https://backend.sorpa.com/club/users/list/";

    useEffect(() => {
        const searchParams = new URLSearchParams(searchDetails);
        url = `https://backend.sorpa.com/users/club/list/?${searchParams}`
        list.reload();
    }, [searchDetails]);
    return (
        <>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16
            }}>
                <div
                    className={"mt-[20px]"}
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16
                        }}>
                            <Button
                                onClick={() => {
                                    setAddClubModal(true);
                                }}
                                style={{
                                    width: 240,
                                    border: "1px solid #BDFF00",
                                    background: "none",
                                    color: "#BDFF00",
                                    fontWeight: 600,
                                    borderRadius: 16
                                }}
                            >
                                <p>Добавить</p>
                            </Button>
                            <p style={{color: "white", fontWeight: "bold"}}>{count} Членов клуба</p>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 24,
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <div style={{width: 300, display:"flex", alignItems:"center", gap:12}}>
                                        <Input
                                            startContent={<SearchIcon/>}
                                            type={"email"}
                                            size={"sm"}
                                            radius="lg"
                                            style={{color: "white"}}
                                            className={"input-text-color"}
                                            classNames={{
                                                label: "input-text-color",
                                                input: "text-white/90",
                                                innerWrapper: "input-text-color",
                                                inputWrapper: "input-main",
                                            }}
                                            placeholder={"Поиск по номеру"}
                                            onChange={(event) => {
                                                setSearchDetails({...searchDetails, search: event.target.value});
                                                if (event.target.value === '') {
                                                    setSearchDetails({...searchDetails, search: ""});
                                                }
                                            }}
                                        />
                                    </div>
                                    {/*</div>*/}
                                    {/*    <div style={{width: 240}}>*/}
                                    {/*        <Select*/}
                                    {/*            placeholder={"Все cтатусы"}*/}
                                    {/*            value={status}*/}
                                    {/*            onChange={(items) => {*/}
                                    {/*                setStatus(items.target.value);*/}
                                    {/*                if (!items.target.value || items.target.value === "Все статусы") {*/}
                                    {/*                    setStatus(null);*/}
                                    {/*                }*/}
                                    {/*            }}*/}
                                    {/*            listboxProps={{*/}
                                    {/*                itemClasses: {*/}
                                    {/*                    base: "wrapper-content"*/}
                                    {/*                },*/}
                                    {/*            }}*/}
                                    {/*            popoverProps={{*/}
                                    {/*                classNames: {*/}
                                    {/*                    content: "wrapper",*/}
                                    {/*                },*/}
                                    {/*            }}*/}
                                    {/*            disallowEmptySelection={false}*/}
                                    {/*            color={"secondary"}*/}
                                    {/*            size={"sm"}*/}
                                    {/*            style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}*/}
                                    {/*            classNames={{*/}
                                    {/*                label: "text-white",*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            {statuses.map((status) => (*/}
                                    {/*                <SelectItem key={status.id} value={status.name}>*/}
                                    {/*                    {status.name}*/}
                                    {/*                </SelectItem>*/}
                                    {/*            ))}*/}
                                    {/*        </Select>*/}
                                </div>
                            </div>
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            {
                                !isLoading && count > 0 && <Table
                                    isStriped isHeaderSticky
                                    classNames={{
                                        base: 'table-wrapper',
                                        table: "table",
                                        wrapper: "base-wrapper max-w-[1500px]"
                                    }}
                                    className={"h-full"}
                                    aria-label="Example table with infinite pagination"
                                    baseRef={scrollerRef}
                                    bottomContent={
                                        hasMore ? (
                                            <div ref={loaderRef} className="flex w-full justify-center">
                                                {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                                            </div>
                                        ) : null
                                    }
                                >
                                    <TableHeader>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>ID</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Имя</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Номер телефона</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold"
                                        }}>Количество мест</TableColumn>
                                        <TableColumn style={{
                                            background: "#525255",
                                            color: "white",
                                            fontWeight: "bold",
                                            textAlign: "center"
                                        }}>Действия</TableColumn>
                                    </TableHeader>
                                    <TableBody
                                        isLoading={isLoading}
                                        items={list.items}
                                        // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                                    >
                                        {(user) => (
                                            <TableRow>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.id}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.name}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.phone_number}</p></TableCell>
                                                <TableCell><p style={{
                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                    margin: 0
                                                }}>{user.club_part}</p></TableCell>
                                                <TableCell style={{width: 120}}>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}>
                                                        <Tooltip content="Начислить баллы">
                                                            <Button isIconOnly={true}
                                                                    onClick={() => {
                                                                        setChangeID(user.id);
                                                                        getUser(user.id);
                                                                        setClubFillModal(true);
                                                                    }}
                                                                    style={{background: "none"}}>
                                                                <WalletIcon/>
                                                            </Button>
                                                        </Tooltip>
                                                        {
                                                            user.id !== 189 &&
                                                            <Tooltip content="Поменять количество мест">
                                                                <Button isIconOnly={true}
                                                                        onClick={() => {
                                                                            setChangeClubPartModal(true);
                                                                            setChangeID(user.id);
                                                                            getUser(user.id);
                                                                        }}
                                                                        style={{background: "none"}}>
                                                                    <EditIcon/>
                                                                </Button>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            user.id !== 189 &&
                                                            <Tooltip content="Удалить члена клуба">
                                                                <Button isIconOnly={true}
                                                                        onClick={() => {
                                                                            setChangeID(user.id);
                                                                            getUser(user.id);
                                                                            setClubDeleteModal(true);
                                                                        }}
                                                                        style={{background: "none"}}>
                                                                    <DeleteIcon/>
                                                                </Button>
                                                            </Tooltip>
                                                        }

                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            }
                            {/*<div>*/}
                            {/*    {*/}
                            {/*        users.length === 0 ?*/}
                            {/*            <div style={{*/}
                            {/*                width: "100%",*/}
                            {/*                height: "calc(100vh - 250px)",*/}
                            {/*                display: "flex",*/}
                            {/*                justifyContent: "center"*/}
                            {/*            }}>*/}
                            {/*                <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По*/}
                            {/*                    вашему запросу ничего не найдено</p>*/}
                            {/*            </div> :*/}
                            {/*            <Table isStriped isHeaderSticky aria-label="Example static collection table"*/}
                            {/*                   classNames={{*/}
                            {/*                       base: 'table-wrapper',*/}
                            {/*                       table: "table",*/}
                            {/*                       wrapper: "base-wrapper"*/}
                            {/*                   }}*/}
                            {/*            >*/}
                            {/*                <TableHeader>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold"*/}
                            {/*                    }}>Имя</TableColumn>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold"*/}
                            {/*                    }}>Номер</TableColumn>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold"*/}
                            {/*                    }}>Сумма последнего пополнения</TableColumn>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold"*/}
                            {/*                    }}>Дата последнего пополнения</TableColumn>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold"*/}
                            {/*                    }}>Баланс</TableColumn>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold"*/}
                            {/*                    }}>Статус</TableColumn>*/}
                            {/*                    <TableColumn style={{*/}
                            {/*                        background: "#525255",*/}
                            {/*                        color: "white",*/}
                            {/*                        fontWeight: "bold",*/}
                            {/*                        textAlign: "center"*/}
                            {/*                    }}>Действия</TableColumn>*/}
                            {/*                </TableHeader>*/}
                            {/*                <TableBody>*/}
                            {/*                    {*/}
                            {/*                        users.map((user, index) => {*/}
                            {/*                            return (*/}
                            {/*                                <TableRow key={index}>*/}
                            {/*                                    <TableCell><p style={{*/}
                            {/*                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",*/}
                            {/*                                        margin: 0*/}
                            {/*                                    }}>{user.name}</p></TableCell>*/}
                            {/*                                    <TableCell><p style={{*/}
                            {/*                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",*/}
                            {/*                                        margin: 0*/}
                            {/*                                    }}>{user.phone_number}</p></TableCell>*/}
                            {/*                                    <TableCell><p style={{*/}
                            {/*                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",*/}
                            {/*                                        margin: 0*/}
                            {/*                                    }}>{user.last_fill}</p></TableCell>*/}
                            {/*                                    <TableCell><p style={{*/}
                            {/*                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",*/}
                            {/*                                        margin: 0*/}
                            {/*                                    }}>{user.last_fill_date}</p></TableCell>*/}
                            {/*                                    <TableCell><p style={{*/}
                            {/*                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",*/}
                            {/*                                        margin: 0*/}
                            {/*                                    }}>{user.balance}</p></TableCell>*/}
                            {/*                                    <TableCell><p style={{*/}
                            {/*                                        borderRight: "1px solid rgba(84, 84, 88, 0.65)",*/}
                            {/*                                        margin: 0*/}
                            {/*                                    }}>{user.user_status}</p></TableCell>*/}
                            {/*                                    <TableCell style={{width: 120}}>*/}
                            {/*                                        <div style={{*/}
                            {/*                                            display: "flex",*/}
                            {/*                                            justifyContent: "center"*/}
                            {/*                                        }}>*/}
                            {/*                                            <Tooltip content="Пополнить баланс">*/}
                            {/*                                                <Button disabled={true} isIconOnly={true}*/}
                            {/*                                                        onClick={() => {setAddBalance(true); setName(user.name); setFillId(user.id);}}*/}
                            {/*                                                        style={{background: "none"}}>*/}
                            {/*                                                    <WalletIcon/>*/}
                            {/*                                                </Button>*/}
                            {/*                                            </Tooltip>*/}
                            {/*                                            <Tooltip content="Анулировать баланс">*/}
                            {/*                                                <Button disabled={true} isIconOnly={true}*/}
                            {/*                                                        onClick={() => {axios.get(`https://backend.sorpa.com/users/${user.id}/zero/`).then(*/}
                            {/*                                                            res => {*/}
                            {/*                                                                setUsers(res.data);*/}
                            {/*                                                            }).catch(*/}
                            {/*                                                            err => {*/}
                            {/*                                                                console.log(err);*/}
                            {/*                                                            }*/}
                            {/*                                                        )*/}
                            {/*                                                        }}*/}
                            {/*                                                        style={{background: "none"}}>*/}
                            {/*                                                    <EditIcon/>*/}
                            {/*                                                </Button>*/}
                            {/*                                            </Tooltip>*/}
                            {/*                                            <Tooltip content="Заблокировать / Разблокировать">*/}
                            {/*                                                <Button disabled={true} onClick={() => {*/}
                            {/*                                                    axios.get(`https://backend.sorpa.com/users/${user.id}/block/unblock/`).then(*/}
                            {/*                                                        res => {*/}
                            {/*                                                            setUsers(res.data);*/}
                            {/*                                                        }).catch(*/}
                            {/*                                                            err => {*/}
                            {/*                                                                console.log(err);*/}
                            {/*                                                            }*/}
                            {/*                                                        )*/}
                            {/*                                                }} isIconOnly={true}*/}
                            {/*                                                        style={{background: "none"}}>*/}
                            {/*                                                    <BlockUser/>*/}
                            {/*                                                </Button>*/}
                            {/*                                            </Tooltip>*/}
                            {/*                                        </div>*/}
                            {/*                                    </TableCell>*/}
                            {/*                                </TableRow>*/}
                            {/*                            )*/}
                            {/*                        })*/}
                            {/*                    }*/}
                            {/*                </TableBody>*/}
                            {/*            </Table>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    </Card>
                </div>
                <Modal isOpen={addClubModal} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Добавить</h2>
                            <Button onClick={() => {
                                setAddClubModal(false);
                                setSendData({
                                    phone_number: "",
                                    places_count: ""
                                });
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <form onSubmit={(e) => {
                                    e.preventDefault();
                                    axios.post(`https://backend.sorpa.com/users/add/club/`,
                                        {...sendData}).then(
                                        res => {
                                            setAddClubModal(false);
                                            setSendData({
                                                phone_number: "",
                                                places_count: ""
                                            });
                                            window.location.reload();
                                        }
                                    ).catch(
                                        err => {
                                            setError(err.response.data.message);
                                        }
                                    )
                                }}
                                  style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    type={"text"}
                                    value={sendData.phone_number}
                                    placeholder="Номер телефона"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    required={true}
                                    onChange={(event) => {
                                        setError("");
                                        setSendData({...sendData, phone_number: event.target.value});
                                    }}
                                />
                                <Input
                                    type={"number"}
                                    value={sendData.places_count}
                                    placeholder="Количество мест"
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    required={true}
                                    onChange={(event) => {
                                        setError("");
                                        setSendData({...sendData, places_count: event.target.value});
                                    }}
                                />
                                <Button
                                    type={"submit"}
                                    style={{
                                        background: "#BF0",
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom:20,
                                        width:"100%"
                                    }}
                                >
                                    <p>Сохранить</p>
                                </Button>
                            </form>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal isOpen={changeClubPartModal} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Изменить Количество мест</h2>
                            <h2 style={{color: "white"}}>{user?.phone_number}</h2>
                            <Button onClick={() => {
                                setChangeID(null);
                                setChangeClubPartModal(false);
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    type={"number"}
                                    value={user.club_part}
                                    placeholder="Количество мест"
                                    size={"sm"}
                                    radius="lg"
                                    maxLength={8}
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setUser({...user, club_part: event.target.value});
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        axios.post(`https://backend.sorpa.com/users/${changeID}/change/club/part/`,
                                            {"places_count": user.club_part}).then(
                                            res => {
                                                window.location.reload();
                                            }
                                        ).catch(
                                            err => {
                                                setError(err.response.data.message);
                                                console.log(err)
                                            }
                                        )
                                    }}
                                    style={{
                                        background: "#BF0",
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom:20,
                                        width:"100%"
                                    }}
                                >
                                    <p>Изменить</p>
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal isOpen={clubDeleteModal} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Вы хотите удалить? (Членство)</h2>
                            <h2 style={{color: "white"}}>{user?.phone_number}</h2>
                            <Button onClick={() => {
                                setChangeID(null);
                                setClubDeleteModal(false);
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Button
                                    onClick={() => {
                                        axios.get(`https://backend.sorpa.com/users/${changeID}/change/club/part/delete/`).then(
                                            res => {
                                                window.location.reload();
                                            }
                                        ).catch(
                                            err => {
                                                setError(err.response.data.message);
                                                console.log(err)
                                            }
                                        )
                                    }}
                                    style={{
                                        background: "",
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom:20,
                                        width:"100%"
                                    }}
                                >
                                    <p>Удалить</p>
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal isOpen={clubFillModal} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Пополнить бонусный счет<br/>{user?.phone_number}</h2>
                            <Button onClick={() => {
                                setClubFillModal(false);
                                setSuccess("");
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            {
                                success && <p style={{color: "green"}}>{success}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    type={"number"}
                                    value={user.fill_sum}
                                    placeholder="Сумма"
                                    size={"sm"}
                                    radius="lg"
                                    maxLength={8}
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setUser({...user, fill_sum: event.target.value});
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        axios.post(`https://backend.sorpa.com/users/${changeID}/change/fill/club/balance/`, {"fill_sum": user.fill_sum}).then(
                                            res => {
                                                setSuccess(res.data.success);
                                            }
                                        ).catch(
                                            err => {
                                                setError(err.response.data.message);
                                                console.log(err)
                                            }
                                        )
                                    }}
                                    style={{
                                        background: "#BF0",
                                        color: "black",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom:20,
                                        width:"100%"
                                    }}
                                >
                                    <p>Пополнить</p>
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default Club;
