import React, {useState} from "react";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Spinner,
    getKeyValue,
    Input, Button,
    Tabs, Tab
} from "@nextui-org/react";
import {useInfiniteScroll} from "@nextui-org/use-infinite-scroll";
import {useAsyncList} from "@react-stately/data";
import axios from "axios";
import ExcelSave from "../../components/ExcelSave";

export default function FillReport() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasMore, setHasMore] = React.useState(false);
    const [billsSum, setBillsSum] = useState(0)
    const [searchDetails, setSearchDetails] = useState({
        date_from: "",
        date_to: ""
    })

    let list = useAsyncList({
        async load({signal, cursor}) {

            if (cursor) {
                setIsLoading(false);
            }

            const res = await axios.get(cursor || url);
            let json = await res.data

            setBillsSum(json?.bills_sum);

            setHasMore(json?.next !== null);

            const decodedURL = decodeURIComponent(json?.next);

            if (Array.isArray(json.results)){
                return {
                    items: json?.results,
                    cursor: decodedURL,
                };
            }else{
                return {
                    items: [],
                    cursor: null,
                };
            }
        },
    });

    const [loaderRef, scrollerRef] = useInfiniteScroll({hasMore, onLoadMore: list.loadMore});

    let url = "https://backend.sorpa.com/pay/fill/report/";

    const submitSearch = () => {
        const temp_search = {}
        if (searchDetails.branch !== ""){
            temp_search['branch'] = searchDetails.branch
        }
        if (searchDetails.place !== ""){
            temp_search['place'] = searchDetails.place
        }
        if (searchDetails.date_from !== ""){
            temp_search['date_from'] = searchDetails.date_from
        }
        if (searchDetails.date_to !== ""){
            temp_search['date_to'] = searchDetails.date_to
        }
        const queryString = new URLSearchParams(temp_search).toString();
        url = `https://backend.sorpa.com/pay/fill/report/?${queryString}`
        list.reload();
    }

    const clearSearch = () => {
        window.location.reload();
    }

    return (
        <div className={"min-h-[500px] flex flex-col gap-[24px]"}>
            <h2 className={"text-white font-bold text-[24px]"}>Общая сумма: {billsSum?.toLocaleString("RU-ru")}</h2>
            <div className={"flex flex-col gap-[24px]"}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    gap: 4,
                    alignItems: "center"
                }}>
                    <div className={"flex gap-[12px]"}>
                        <Input
                            type={"date"}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={searchDetails.date_from}
                            placeholder={"Название филиала"}
                            onChange={(event) => {
                                setSearchDetails({...searchDetails, date_from: event.target.value});
                            }}
                        />
                        <Input
                            type={"date"}
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={searchDetails.date_to}
                            placeholder={"Название филиала"}
                            onChange={(event) => {
                                setSearchDetails({...searchDetails, date_to: event.target.value});
                            }}
                        />
                    </div>
                    <Button style={{
                        height: "44px",
                        background: "#BF0",
                        color: "black",
                        fontWeight: 600
                    }}
                            onClick={() => {submitSearch()}}
                    >
                        <p>Найти</p>
                    </Button>
                </div>
                <Table
                    isStriped isHeaderSticky
                    classNames={{
                        base: 'table-wrapper',
                        table: "table",
                        wrapper: "base-wrapper max-w-[1500px]"
                    }}
                    className={"h-full"}
                    aria-label="Example table with infinite pagination"
                    baseRef={scrollerRef}
                    bottomContent={
                        hasMore ? (
                            <div ref={loaderRef} className="flex w-full justify-center">
                                {/*<Spinner  color="transparent" className={"bg-transparent text-transparent"} />*/}
                            </div>
                        ) : null
                    }
                >
                    <TableHeader>
                        <TableColumn key="id" style={{background: "#525255", color: "white", fontWeight: "bold"}}>ID пользователя</TableColumn>
                        <TableColumn key="name" style={{background: "#525255", color: "white", fontWeight: "bold", maxWidth:250, width:250}}>Имя</TableColumn>
                        <TableColumn key="bill_id" style={{background: "#525255", color: "white", fontWeight: "bold", maxWidth:150}}>Уникальный номер (Береке)</TableColumn>
                        <TableColumn key="amount" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Сумма</TableColumn>
                        <TableColumn key="bill_status" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Статус</TableColumn>
                        <TableColumn key="created_at" style={{background: "#525255", color: "white", fontWeight: "bold"}}>Дата проведения</TableColumn>
                    </TableHeader>
                    <TableBody
                        isLoading={isLoading}
                        items={list.items}
                        // loadingContent={<Spinner color="transparent" className={"bg-transparent -transparent"}/>}
                    >
                        {(item) => (
                            <TableRow key={item.name}>
                                {(columnKey) => <TableCell>{columnKey && getKeyValue(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}
